import React, { Component } from 'react'
import Menu from '../Menu'

// TODO: Vi har API access till untapped, hör av till Nisse om intressant! Kom igen, det blir kul

class Beer extends Component {
  render () {
    return (
      <div className='beer'>
        <Menu></Menu>
      </div>
    )
  }
}

export default Beer
